import React, { useState } from 'react'
import {
  withStyles,
  TableRow,
  TableCell,
  TextField,
  Button,
  Popover,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Card
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { getPrices } from '@/webservices/DataSource'
import defaultImage from '@/assets/img/default-image.jpg'
import Alert from '@material-ui/lab/Alert';
import FormatListBulletedOutlinedIcon from '@material-ui/icons/FormatListBulletedOutlined';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import WallpaperIcon from '@material-ui/icons/Wallpaper';

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow)

const useStyles = makeStyles(theme => ({
  smallIcon: {
    width: '28px',
    height: '28px',
    margin: '0 auto'
  },
  cardRoot: {
    minWidth: 250,
  },
  actionCell: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  smCell: {
    padding: '6px'
  },
  qtyInput: {
    width: '60px'
  },
  addBtn: {
    padding: '4px',
    height: '30px',
    marginLeft: theme.spacing(1)
  },
  getPriceBtn: {
    padding: '2px',
    height: '30px',
    textTransform: 'none',
  },
  right: {
    textAlign: 'right'
  },
  center: {
    textAlign: 'center'
  },
  popoverClass: {
    cursor: 'pointer'
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
  media: {
    width: '100%',
    height: 350
  },
  defaultMedia: {
    width: 250,
    height: 150,
    padding: 24
  },
  infoText: {
    width: 260,
  }
}))

const fetchPrice = async id => {
  const res = await getPrices(id)
  return res.bestPrice
}

const thumbString = (str) => {
  let arr = str.split('.')
  arr[arr.length - 1] = `th.${arr[arr.length - 1]}`
  return arr.join('.')
}

export const ResultRow = props => {
  const classes = useStyles()
  const product = props.data
  const thumbnailImg = product.image ? thumbString(product.image) : null
  const account = props.account
  const dptId = props.account?.department_id
  const tag2FobUsd = Number(product.tagL2) || 0
  const tag3FobAud = Number(product.tagL3) || 0
  const isBREorGC = account.department_id === 7 || account.department_id === 8
  const isBREorSYDandPirelli = (account.department_id === 7 || account.department_id === 10) && product.name.toLowerCase().indexOf("pirelli") !== -1
  const isNoStockorOnWay = product.availability === "On the Way" || product.availability === "out of stock"
  const isBREandPirelliStock = account.department_id === 7 && product.name.toLowerCase().indexOf("pirelli") !== -1
  const isCONTINENTAL = product.name.toUpperCase().indexOf("CONTINENTAL") !== -1
  const isNoStockorOnWayBesidePirelli = isNoStockorOnWay && !isBREandPirelliStock && !isCONTINENTAL
  const [bestPrice, setBestPrice] = useState(null)
  const [qty, setQty] = useState('')


  const handleQtyChange = event => {
    setQty(event.target.value)
  }

  const handleGetPrice = async () => {
    try {
      const price = Number.parseFloat(await fetchPrice(product.id))
      setBestPrice(price)
    } catch (error) {
      if(error.message){
        props.setAlertMsg(error.message)
      }
      console.error(error)
    }
  }

  const stockTextConvert = () => {
    if (product.availability === "8+") {
      return 8
    } else if (isNoStockorOnWay) {
      return 0
    } else {
      return Number.parseInt(product.availability)
    }
  }

  const pirelliStockDisplay = (val) => {
    return val === 0 ? "out of stock" : val
  }

  const pirelli3PStockDisplay = (val) => {
    if (isNoStockorOnWay) {
      // 如果货品本体无库存，则显示3PL的值
      return pirelliStockDisplay(val)
    } else if (!product.isNoStockorOnWay && Number.parseInt(val) > stockTextConvert()) {
      // 如果货品本体有库存，但库存小于3PL，则显示3PL的值
      return pirelliStockDisplay(val)
    } else {
      return product.availability
    }
  }

  //add按钮当qty为空或0或负数，或者为无库存时
  let isAddBtnDisable = Number.parseInt(qty) < 1 || qty === '' || isNoStockorOnWay
  //但是当为布里斯班并且货品为【PIRELLI】时，则可以添加Add----------【PIRELLI】
  if (isBREandPirelliStock) {
    isAddBtnDisable = false
  }

  const handleAddToCart = async () => {
    let special = Number.parseFloat(product.specialPrice)
    //只要不是A类客户，并且是布里斯班或GC的客户，则重新把特价改为0，避免走下方对应逻辑
    if (account.price_strategy !== "Class A" && isBREorGC) {
      special = 0
    }

    if (dptId === 14) { //特殊处理货柜账套的情况
      const price = Number.parseFloat(product.tagL2) || 0
      props.addToCart(product, Number.parseInt(qty) || 1, price)
      return
    }
    //没获取过 bestPrice
    if (bestPrice === null) {
      if (!special) {//为0或为空
        // 没获取过 bestPrice 且 special!=0时提示
        props.setAlertMsg('Please click "Get Price", then add it to Cart')
        return
      }
      const price = Number.parseFloat(await fetchPrice(product.id))
      if (price <= 6) {
        // 如果bestprice<=6，则弹出提示：“Please call us to order this product, Thanks”，并且不将该货品加入购物车
        props.setAlertMsg('Please call us to order this product, Thanks')
        return
      }
      setBestPrice(price)
      if (special > 0 && product.is_special_offer !== false) {
        props.addToCart(product, Number.parseInt(qty) || 1, special)
      } else {
        //肯定走这里---bestPrice === null
        props.addToCart(product, Number.parseInt(qty) || 1, price)
      }
    } else {
      if (bestPrice <= 6) {
        // 如果bestprice<=6，则弹出提示：“Please call us to order this product, Thanks”，并且不将该货品加入购物车
        props.setAlertMsg('Please call us to order this product, Thanks')
        return
      }
      if (special > 0 && product.is_special_offer !== false) {
        props.addToCart(product, Number.parseInt(qty) || 1, special)
      } else {
        //肯定走这里---bestPrice不为空
        props.addToCart(product, Number.parseInt(qty) || 1, bestPrice)
      }
    }
  }

  let bestPriceCell = null
  // if (isNoStockorOnWay ) {
  if (isNoStockorOnWayBesidePirelli) {
    //当无库存时，不予显示best price按钮,直接显示n/a
    //此处条件中补充一个例外：当布里斯班并且为【PIRELLI】时,还是可以GET价格 ----------【PIRELLI】
    bestPriceCell = 'n/a'
  } else if (bestPrice === null) {
    bestPriceCell = (
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        className={classes.getPriceBtn}
        onClick={() => handleGetPrice()}
      >
        Get Price
      </Button>
    )
  } else {
    bestPriceCell = bestPrice.toFixed(2)
  }

  const [popoverOpenName, setPopoverOpenName] = useState(false)
  const [popoverOpenAval, setPopoverOpenAval] = useState(false)
  const [anchorElName, setAnchorElName] = useState(null);
  const [anchorElAval, setAnchorElAval] = useState(null);

  const handlePopoverOpenName = (event) => {
    setAnchorElName(event.currentTarget);
    setPopoverOpenName(true)
  }

  const handlePopoverOpenAval = (event) => {
    setAnchorElAval(event.currentTarget);
    setPopoverOpenAval(true)
  }
  const handlePopoverClose = () => {
    setAnchorElAval(null);
    setAnchorElName(null);
    setPopoverOpenAval(false);
    setPopoverOpenName(false)
  }

  const stockIconColor = () => {
    let arr = product.warehouse_availability
    let lengthArr = arr.length
    let colorVal = '#263238' //dark

    if (lengthArr > 1) {
      if (arr[0].availability !== 'out of stock') {
        colorVal = '#4caf50' //green
      } else if (arr[1].availability !== 'out of stock' || arr[lengthArr - 1].availability !== 'out of stock') {
        colorVal = '#ffb404' //yellow
      } else {
        colorVal = '#afafaf' //grey
      }
    }
    return <FormatListBulletedOutlinedIcon style={{ color: colorVal }} />
  }

  const avalDisplay = () => {
    if (product.tagL4.toLowerCase() === "call") {
      return (<div>Call for Availability</div>)
    } else if (isBREorSYDandPirelli) {
      // 当为BNE或SYD 的倍耐力货品，则交给如下专门函数处理
      return pirelli3PStockDisplay(product.stock_3rdP)
    } else if (product.warehouse_availability.length === 1) {
      return product.availability
    }
    // @Yoxiky 20241029:嗯，取消吧，需要显示各个仓库的库存
    // else if (account.department_id === 4) {
    //   // 当为墨尔本时，直接显示总计,不显示分仓明细
    //   return product.availability
    // } 
    else {
      return (
        <div
          onMouseEnter={handlePopoverOpenAval}
          onMouseLeave={handlePopoverClose}
        >
          {/* Zapp账套专属逻辑 */}
          {dptId === 12 || dptId === 8 ? stockIconColor() : <FormatListBulletedOutlinedIcon />}
          <Popover
            className={classes.popover}
            classes={{
              paper: classes.paper,
            }}
            open={popoverOpenAval}
            anchorEl={anchorElAval}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <List>
              {product.warehouse_availability.map(item => (
                <ListItem key={item.id}>
                  {/* //OLD 此处增加三元判断，当倍耐力时一律显示in stock------【PIRELLI】 */}
                  {/* //NEW 此处增加三元判断，当倍耐力时一律显示第3方库存------【PIRELLI】 */}
                  {isBREandPirelliStock ? <ListItemText primary={pirelliStockDisplay(product.stock_3rdP)} /> : <ListItemText primary={item.availability} />}
                  <ListItemIcon>
                    <ArrowRightOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.name} />
                </ListItem>
              ))}
            </List>
            {
              dptId === 8 ?
                <Alert severity="warning"
                  className={classes.infoText}>
                  To order Brisbane stock, please call 07 3276 8792, and we will deliver on every Tuesday and Thursday.</Alert>
                : <Alert severity="info"
                  className={classes.infoText}>
                  If tyres are transferred from other sub-warehouses,
                  they can only be delivered on the next workday.</Alert>
            }

          </Popover>
        </div>
      )
    }
  }

  const specialPriceDisplay = () => {
    if (product.specialPrice === 0 || product.is_special_offer === false) {
      //如果是否特价为否，或者特价为0，则不显示特价
      return ""
    }
    if (account.price_strategy !== "Class A" && isBREorGC) {
      //只要是布里斯班或GC的客户，并且不是A类客户，则不显示特价
      return ""
    }
    // CONTINENTAL 品牌，即使库存为0 也显示特价
    if (isNoStockorOnWay && !isCONTINENTAL) {
      //只要库存是0，则不显示特价
      return ""
    }
    return product.specialPrice
  }

  const imgPopover = () => {
    return <Popover
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={popoverOpenName}
      anchorEl={anchorElName}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={handlePopoverClose}
      disableRestoreFocus
    >
      <Card className={classes.cardRoot}>
        {product.image
          ? <img alt="item img" src={product.image} className={classes.media} variant="square" />
          : <img alt="item img" src={defaultImage} className={classes.defaultMedia} variant="square" />
        }
      </Card>
    </Popover>
  }

  const thumbnailCom = () => {
    return thumbnailImg ?
      <Avatar
        variant="rounded"
        onMouseEnter={handlePopoverOpenName}
        onMouseLeave={handlePopoverClose}
        alt="Tyre image" src={thumbnailImg}
      />
      :
      <Avatar
        className={classes.smallIcon}
        variant="rounded">
        <WallpaperIcon />
      </Avatar>
  }

  return (
    <StyledTableRow>
      {dptId === 14 && <TableCell classes={{ sizeSmall: classes.smCell }}>
        {thumbnailCom()}
        {imgPopover()}
      </TableCell>}
      <TableCell classes={{ sizeSmall: classes.smCell }}>
        {dptId === 14 ?
          <div style={{ lineHeight: '14px', paddingTop: 4 }}>
            <span style={{ fontWeight: 'bold' }}>{product.name}</span> <br />
            <span style={{ fontSize: 8, fontStyle: 'italic', color: '#bcbcbc' }}>{product.originCode || product.id}.{account.id}</span>
          </div>
          :
          <>
            <div
              className={classes.popoverClass}
              onMouseEnter={handlePopoverOpenName}
              onMouseLeave={handlePopoverClose}>
              {product.name}
            </div>
            {imgPopover()}
          </>
        }
      </TableCell>
      <TableCell classes={{ sizeSmall: classes.smCell }}>
        <span style={{ fontStyle: 'italic' }}>{product.brand}</span>
      </TableCell>
      <TableCell
        classes={{ sizeSmall: classes.smCell }}
        className={classes.right}
      >
        {dptId === 14 ? <span style={{ marginRight: 16, fontSize: 12 }}>in stock</span> : avalDisplay()}
      </TableCell>
      <TableCell
        classes={{ sizeSmall: classes.smCell }}
        className={classes.center}
      >
        {dptId === 14 ?
          <span style={{ color: '#56a136', fontWeight: 'bold', fontStyle: 'italic' }}>{tag3FobAud}</span>
          :
          specialPriceDisplay()
        }
      </TableCell>
      <TableCell
        classes={{ sizeSmall: classes.smCell }}
        className={classes.center}
      >
        {dptId === 14 ?
          <span style={{ color: '#0b81d6', fontWeight: 'bold', fontStyle: 'italic' }}>{tag2FobUsd}</span>
          :
          bestPriceCell
        }
      </TableCell>
      <TableCell
        className={classes.actionCell}
        classes={{ sizeSmall: classes.smCell }}
      >
        <TextField
          type="number"
          InputLabelProps={{
            shrink: true
          }}
          margin="dense"
          className={classes.qtyInput}
          value={qty}
          inputProps={{ min: '1', step: '1' }}
          onChange={handleQtyChange}
        />
        <Button
          disabled={isAddBtnDisable}
          variant="contained"
          color="primary"
          size="small"
          className={classes.addBtn}
          onClick={handleAddToCart}
        >
          Add
        </Button>
      </TableCell>
    </StyledTableRow>
  )
}
