import React, { useState } from 'react'
import { SnackbarContent, IconButton } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  info: {
    backgroundColor: '#EBF7FF',
    color: '#649DFC'
  },
  infoWarning: {
    backgroundColor: '#FFFBE6',
    color: '#CB8B12'
  },
  container: {
    display: 'flex',
    fontSize: '14px',
    '& >p': {
      marginLeft: theme.spacing(2)
    }
  }
}))
export const InfoSnackBar = props => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  if (open) {
    return (
      <SnackbarContent
        className={props.holidayNotice?classes.infoWarning:classes.info}
        message={
          <div className={classes.container}>
            <InfoOutlinedIcon />
            <p>{props.children}</p>
          </div>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    )
  } else {
    return null
  }
}
