import React, { useState, useEffect } from 'react'
import { Card, CardContent, Typography, Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { getBalance } from '@/webservices/DataSource'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& > div:not(:first-child)': {
      marginTop: theme.spacing(2)
    }
  },
  row: {
    display: 'flex'
  },
  title: {
    flex: '0 0 200px',
    textAlign: 'right'
  },
  content: {
    paddingLeft: theme.spacing(2),
    flex: '1 0 auto'
  },
  red: {
    color: theme.palette.textRed
  }
}))

export const Profile = props => {
  const [outStanding, setOutStanding] = useState(0)
  const [overDue, setOverDue] = useState(0)

  const fetchBalance = async () => {
    const data = await getBalance()
    setOutStanding(data.data.creditLimit)
    setOverDue(data.data.xeroBalance)
  }
  useEffect(() => {
    fetchBalance()
  }, [])

  const classes = useStyles()
  return (
    <Card>
      <CardContent className={classes.container}>
        <div className={classes.row}>
          <Typography className={classes.title} variant="subtitle1">
            Customer Name
          </Typography>
          <Typography className={classes.content} variant="subtitle2">
            {props.name}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title} variant="subtitle1">
            Default Address
          </Typography>
          <Typography className={classes.content} variant="subtitle2">
            {props.address}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title} variant="subtitle1">
            Phone
          </Typography>
          <Typography className={classes.content} variant="subtitle2">
            {props.phone}
          </Typography>
        </div>
        <div className={classes.row}>
          <div className={classes.title}></div>
          <Divider className={classes.content} />
        </div>

        <div className={classes.row}>
          <Typography className={classes.title} variant="subtitle1">
            Credit Limit
          </Typography>
          <Typography
            className={clsx(classes.content, classes.red)}
            variant="subtitle2"
          >
            {outStanding}
          </Typography>
        </div>
        <div className={classes.row}>
          <Typography className={classes.title} variant="subtitle1">
            Receivable Owe
          </Typography>
          <Typography
            className={clsx(classes.content, classes.red)}
            variant="subtitle2"
          >
            {overDue}
          </Typography>
        </div>
      </CardContent>
    </Card>
  )
}
