import React, { useState, useEffect } from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Select,
  MenuItem,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { postOrder } from '@/webservices/DataSource'
import { MessageAlert } from '@/components/MessageAlert'

const useStyles = makeStyles(theme => ({
  container: {
    width: '80%'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  root: {
    width: '100%',
    '&>div:not(:first-child)': {
      marginTop: theme.spacing(1)
    }
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    flex: '0 0 100px'
  },
  content: {
    flex: '1 0 0'
  },
  textDense: {
    '& input': {
      paddingTop: '6.5px',
      paddingBottom: '6.5px'
    }
  }
}))

const mapStateToProps = state => ({
  info: state.common
})

export const InfoConfirm = connect(
  mapStateToProps,
  null
)(props => {
  const classes = useStyles()
  const [phone, setPhone] = useState(props.info.phone || '')
  const [address, setAddress] = useState(props.info.address || '')
  const [shipping, setShipping] = useState(1)
  const [note, setNote] = useState('')

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  const [alertType] = useState('success')

  useEffect(() => {
    setPhone(props.info.phone)
    setAddress(props.info.address)
  }, [props.info])

  const uuid =
    'cus_' +
    Math.random()
      .toString(36)
      .substr(2, 9)

  const handlePhoneChange = event => {
    setPhone(event.target.value)
  }

  const handleAddressChange = event => {
    setAddress(event.target.value)
  }

  const handleDeliveryChange = event => {
    setShipping(event.target.value)
  }

  const handleNoteChange = event => {
    setNote(event.target.value)
  }

  const handleSubmit = () => {
    const params = { uuid, phone, address, shipping, note }
    params.lines = props.cart.map((item, index) => ({
      id: `new_${index + 1}`,
      seq: index + 1,
      productId: item.product.id,
      quantity: item.quantity,
      price:item.price
    }))

    postOrder(params)
      .then(data => {
        setAlertMsg('Created success')
        setAlertOpen(true)
        props.submitCallback()
      })
      .catch(err => {})
  }
  return (
    <React.Fragment>
      <MessageAlert
        open={alertOpen}
        closeCallback={setAlertOpen}
        message={alertMsg}
        variant={alertType}
      />
      <Dialog
        maxWidth="md"
        open={props.open}
        fullWidth
        className={classes.container}
        disableBackdropClick
        disableEscapeKeyDown
      >
        <DialogTitle>
          <Typography variant="h4" component="span">
            Step 2 : Confirm Infos
          </Typography>
        </DialogTitle>
        <DialogContent>
          <form className={classes.form}>
            <div className={classes.root}>
              <div className={classes.row}>
                <div className={classes.label}>
                  <Typography>Address</Typography>
                </div>
                <div className={classes.content}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    value={address}
                    onChange={handleAddressChange}
                    fullWidth
                    classes={{ root: classes.textDense }}
                  ></TextField>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.label}>
                  <Typography>Phone</Typography>
                </div>
                <div className={classes.content}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    value={phone}
                    onChange={handlePhoneChange}
                    fullWidth
                    classes={{ root: classes.textDense }}
                  ></TextField>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.label}>
                  <Typography>Shipping</Typography>
                </div>
                <div className={classes.content}>
                  <Select value={shipping} onChange={handleDeliveryChange}>
                    <MenuItem value={1}>Delivery</MenuItem>
                    <MenuItem value={2}>Pickup</MenuItem>
                  </Select>
                </div>
              </div>
              <div className={classes.row}>
                <div className={classes.label}>
                  <Typography>Note</Typography>
                </div>
                <div className={classes.content}>
                  <TextField
                    margin="dense"
                    variant="outlined"
                    rows="4"
                    value={note}
                    onChange={handleNoteChange}
                    fullWidth
                    multiline
                    classes={{ root: classes.textDense }}
                  ></TextField>
                </div>
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.cancelCallback}>Back</Button>
          <Button color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
})
