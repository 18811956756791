import React from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  withStyles
} from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  smCell: {
    padding: '6px'
  },
  header: {
    backgroundColor: '#D7D7D7',
    color: '#000000',
    fontWeight: 700,
    fontSize: '12px'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  },
  viewBtn: {
    padding: '4px',
    height: '30px'
  },
  textBlue: {
    color: theme.palette.textBlue
  }
}))

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow)

export const OrderList = withRouter(props => {
  const classes = useStyles()

  const { history } = props
  return (
    <Table size="small">
      <TableHead>
        <TableRow className={classes.header}>
          <TableCell className={classes.center}>#</TableCell>
          <TableCell className={classes.center}>Action</TableCell>
          <TableCell className={classes.center}>Number</TableCell>
          <TableCell className={classes.center}>Type</TableCell>
          <TableCell className={classes.center}>Status</TableCell>
          <TableCell className={clsx(classes.center, classes.textBlue)}>
            QTY
          </TableCell>
          <TableCell className={clsx(classes.center, classes.textBlue)}>
            Amount
          </TableCell>
          <TableCell className={classes.center}>Date</TableCell>
          <TableCell className={classes.center}>Due Date</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data.map((item, index) => (
          <StyledTableRow key={item.id}>
            <TableCell
              className={classes.center}
              classes={{ sizeSmall: classes.smCell }}
            >
              {index + 1}
            </TableCell>
            <TableCell
              className={classes.center}
              classes={{ sizeSmall: classes.smCell }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  history.push(`/orders/${item.id}`)
                }}
                size="small"
                className={classes.viewBtn}
              >
                View
              </Button>
            </TableCell>
            <TableCell
              className={classes.center}
              classes={{ sizeSmall: classes.smCell }}
            >
              {item.no}
            </TableCell>
            <TableCell
              classes={{ sizeSmall: classes.smCell }}
              className={classes.center}
            >
              {item.type}
            </TableCell>
            <TableCell
              classes={{ sizeSmall: classes.smCell }}
              className={classes.center}
            >
              {item.status}
            </TableCell>
            <TableCell
              classes={{ sizeSmall: classes.smCell }}
              className={clsx(classes.center, classes.textBlue)}
            >
              {item.qty}
            </TableCell>
            <TableCell
              classes={{ sizeSmall: classes.smCell }}
              className={clsx(classes.center, classes.textBlue)}
            >
              {item.amount}
            </TableCell>
            <TableCell
              classes={{ sizeSmall: classes.smCell }}
              className={classes.center}
            >
              {item.date}
            </TableCell>
            <TableCell
              classes={{ sizeSmall: classes.smCell }}
              className={classes.center}
            >
              {item.dueDate}
            </TableCell>
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  )
})
