import React from 'react'
import { TextField, Divider, Button, Typography } from '@material-ui/core'
import { ToggleSelection } from '@/components/ToggleSelection'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    flex: '0 0 100px'
  },
  content: {
    flex: '1 0 0'
  },
  textDense: {
    '& input': {
      paddingTop: '6.5px',
      paddingBottom: '6.5px'
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(1)
  },
  cancel: {
    marginLeft: theme.spacing(2)
  }
}))

export const TyreFilter = props => {
  const classes = useStyles()
  const { categories, brands, express, dptId,dp14CateL1,dp14CateL2TBR,dp14CateL2PCR,dp14CateToggle} = props
  return (
    <form>
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.label}>
            <Typography>Quick Spec.</Typography>
          </div>
          <div className={classes.content}>
            <TextField
              margin="dense"
              placeholder="example:2055516 or any keyword in product name"
              variant="outlined"
              fullWidth
              classes={{ root: classes.textDense }}
              value={props.spec}
              onChange={props.specCallback}
              // onKeyDown={(e) => (console.log(e.keyCode))}
            />
          </div>
        </div>
      </div>
      {dptId===14 &&
        <>
          <ToggleSelection {...dp14CateL1} />
          {dp14CateToggle==='TBR' && <ToggleSelection {...dp14CateL2TBR} />}
          {dp14CateToggle==='PCR' && <ToggleSelection {...dp14CateL2PCR} />}
        </>
      }
      {dptId !==14 && <ToggleSelection {...categories} />}
      <ToggleSelection {...brands} />
      {dptId !==14 && <ToggleSelection {...express} />}
      <Divider />
      <div className={classes.buttonContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => props.searchCallback()}
        >
          Search
        </Button>
        <Button
          variant="contained"
          className={classes.cancel}
          onClick={props.resetCallback}
        >
          Reset
        </Button>
      </div>
    </form>
  )
}
