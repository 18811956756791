import React, { useState, useEffect } from 'react'
import { Grid, Typography, Button, TextField} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import validate from 'validate.js'
import { login as loginApi } from '@/webservices/Auth'

const schema = {
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  container: {
    height: '60%'
  },
  logoContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%'
  },
  logo: {
    width: '100%'
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    textAlign: 'center',
    marginTop: theme.spacing(3)
  },
  suggestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  forget: {
    textAlign: 'center'
  }
}))

const SignIn = props => {
  const classes = useStyles()
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  })

  useEffect(() => {
    const errors = validate(formState.values, schema)

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }))
  }, [formState.values])

  const handleChange = event => {
    event.persist()

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }))
  }

  const handleSignIn = async event => {
    event.preventDefault()

    const data = await loginApi(
      formState.values.email,
      formState.values.password
    )
    const accessToken = data.token_type + ' ' + data.access_token
    localStorage.setItem('access_token', accessToken)
    props.loginCallback(accessToken)
  }
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false

  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
        alignItems="center"
        justify="center"
      >
        <Grid item xs={8} className={classes.container}>
          <div className={classes.grid}>
            <Grid container className={classes.grid}>
              <Grid className={classes.logoContainer} item lg={5}>
                <img
                  src="/static/img/logo.png"
                  alt=""
                  className={classes.logo}
                />
              </Grid>
              <Grid className={classes.content} item lg={7} xs={12}>
                <div className={classes.content}>
                  <div className={classes.contentBody}>
                    <form className={classes.form} onSubmit={handleSignIn}>
                      <Typography className={classes.title} variant="h1">
                        Sign in
                      </Typography>
                      <Typography
                        color="textSecondary"
                        className={classes.suggestion}
                        gutterBottom
                      >
                        Sign in with your account email and password:
                      </Typography>

                      <TextField
                        className={classes.textField}
                        error={hasError('email')}
                        fullWidth
                        helperText={
                          hasError('email') ? formState.errors.email[0] : null
                        }
                        label="Email Account"
                        name="email"
                        onChange={handleChange}
                        type="text"
                        value={formState.values.email || ''}
                        variant="outlined"
                      />
                      <TextField
                        className={classes.textField}
                        error={hasError('password')}
                        fullWidth
                        helperText={
                          hasError('password')
                            ? formState.errors.password[0]
                            : null
                        }
                        label="Password"
                        name="password"
                        onChange={handleChange}
                        type="password"
                        value={formState.values.password || ''}
                        variant="outlined"
                      />
                      <Button
                        className={classes.signInButton}
                        color="primary"
                        disabled={!formState.isValid}
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                      >
                        Sign in
                      </Button>
                      {/* <Typography
                        className={classes.forget}
                        color="textSecondary"
                        variant="body1"
                      >
                        or{' '}
                        <Link component={RouterLink} to="/sign-up" variant="h6">
                          Forgot/Change password?
                        </Link>
                      </Typography> */}
                    </form>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default SignIn
