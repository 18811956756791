import React from 'react'
import {
  Card,
  CardContent,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { CheckedDetailsRow } from './CheckedDetailsRow'

// const StyledTableRow = withStyles(theme => ({
//   root: {
//     '&:nth-of-type(even)': {
//       backgroundColor: theme.palette.background.default
//     }
//   }
// }))(TableRow)

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: '#D7D7D7',
    color: '#000000',
    fontWeight: 700,
    fontSize: '12px'
  },
  center: {
    textAlign: 'center'
  },
  bold: {
    fontWeight: 'bold'
  }
}))
export const CheckedDetails = props => {
  const classes = useStyles()

  let rows = null
  if (Array.isArray(props.data) && props.data.length === 0) {
    rows = (
      <TableRow>
        <TableCell colSpan="9" className={classes.center}>
          No Data
        </TableCell>
      </TableRow>
    )
  } else if (Array.isArray(props.data) && props.data.length > 0) {
    rows = props.data.map(item => (
      <CheckedDetailsRow key={item.Number} item={item} />
    ))
  }
  return (
    <Card>
      <CardContent>
        <Table size="small">
          <TableHead>
            <TableRow className={classes.header}>
              <TableCell className={classes.center}>Action</TableCell>
              <TableCell className={classes.center}>Invoice Number</TableCell>
              <TableCell className={classes.center}>Date</TableCell>
              <TableCell className={classes.center}>Reference</TableCell>
              <TableCell className={classes.center}>Due Date</TableCell>
              <TableCell className={clsx(classes.center, classes.bold)}>
                Total
              </TableCell>
              <TableCell className={clsx(classes.center, classes.bold)}>
                Paid
              </TableCell>
              <TableCell className={clsx(classes.center, classes.bold)}>
                Credited
              </TableCell>
              <TableCell className={clsx(classes.center, classes.bold)}>
                Credited
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows}</TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}
