import React, { useEffect, useState } from 'react'
import { MessageAlert } from '@/components/MessageAlert'
const AlertFast = ({ msg, type, onClose }) => {
  const [alertOpen, setAlertOpen] = useState(false)
  useEffect(() => {
    if (msg) {
      setAlertOpen(true)
    } else {
      setAlertOpen(false)
    }
  }, [msg])
  return (
    <MessageAlert
      open={alertOpen}
      closeCallback={onClose}
      message={msg}
      variant={type}
    />
  )
}
export default AlertFast
