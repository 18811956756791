import React, { useState } from 'react'
import {
  Grid,
  Button,
  Typography,
  Backdrop,
  CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoSnackBar } from '../Orders/InfoSnackBar'
import { Profile } from './Profile'
import { CheckedDetails } from './CheckedDetails'
import { connect } from 'react-redux'
import { getAgedReceivables } from '@/webservices/DataSource'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  header: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  titleBtn: {
    marginLeft: theme.spacing(2)
  },
  recieveDetail: {
    display: 'flex'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 99999,
    color: '#fff'
  }
}))

const mapStateToProps = state => ({
  account: state.common
})

const Msg = () => {
  return (
    <React.Fragment>
      If you have any questions about the following information or need to
      modify, please contact our customer service staff.
    </React.Fragment>
  )
}
export function Main(props) {
  const classes = useStyles()

  const [agedReceivables, setAgedReceivables] = useState(null)

  const [loading, setLoading] = useState(false)

  const handleAgedReceivables = async () => {
    setLoading(true)
    try {
      const data = await getAgedReceivables()
      setAgedReceivables(data.data)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }
  return (
    <React.Fragment>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <InfoSnackBar>
            <Msg />
          </InfoSnackBar>
        </Grid>
        <Grid item xs={12} className={classes.header}>
          <div className={classes.title}>
            <Typography variant="h4">Profile</Typography>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.filter}>
          <Profile {...props.account} />
        </Grid>
        <Grid item xs={12} className={classes.recieveDetail}>
          <div className={classes.title}>
            <Typography variant="h4">Aged Receivables Detail </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.titleBtn}
              onClick={handleAgedReceivables}
            >
              Check
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <CheckedDetails data={agedReceivables} />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export const Account = connect(mapStateToProps, null)(Main)
