import React from 'react'
import { TextField, Divider, Button, Typography } from '@material-ui/core'
import { ToggleSelection } from '@/components/ToggleSelection'
import { makeStyles } from '@material-ui/styles'
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    flex: '0 0 100px'
  },
  content: {
    flex: '1 0 0'
  },
  textDense: {
    '& input': {
      paddingTop: '6.5px',
      paddingBottom: '6.5px'
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(1)
  },
  cancel: {
    marginLeft: theme.spacing(2)
  }
}))

export const PirreliFilter = props => {
  const classes = useStyles()
  // const { categories, brands, express } = props
  const { categories, brands} = props
  return (
    <form>
      <div className={classes.root}>
        <Alert severity="success">We need up to 20 minutes to confirm your orders that include CONTINENTAL items.</Alert>
        <br/>
        <div className={classes.row}>
          <div className={classes.label}>
            <Typography>Quick Spec.</Typography>
          </div>
          <div className={classes.content}>
            <TextField
              margin="dense"
              placeholder="example:2055516 or any keyword in product name"
              variant="outlined"
              fullWidth
              classes={{ root: classes.textDense }}
              value={props.spec}
              onChange={props.specCallback}
            />
          </div>
        </div>
      </div>

      <ToggleSelection {...brands} />
      <ToggleSelection {...categories} />
      {/* <ToggleSelection {...express} /> */}
      <Divider />
      <div className={classes.buttonContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => props.searchCallback()}
        >
          Search
        </Button>
        <Button
          variant="contained"
          className={classes.cancel}
          onClick={props.resetCallback}
        >
          Reset
        </Button>
      </div>
    </form>
  )
}
