import { UPDATE_ACCOUNT } from './ActionTypes'

export const CommonReducer = (store, action) => {
  switch (action.type) {
    case UPDATE_ACCOUNT:
      return { ...action.payload }
    default:
      return { ...store }
  }
}
