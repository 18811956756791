import React from 'react'
import {
  ListItem,
  Typography,
  TextField,
  Button,
  makeStyles
} from '@material-ui/core'
import { connect } from 'react-redux'
import { updateCartQty, deleteCartItem } from '@/store/CartActionCreator'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    borderBottom: '1px solid #eeeeee'
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  inner: {
    display: 'flex',
    alignItems: 'center'
  },
  qty: {
    width: '56px',
    '& input': {
      // paddingTop: '6.5px',
      paddingBottom: '6.5px',
      paddingLeft: '5px',
      fontSize: '15px'
    }
  },
  amount: {
    fontSize: '12px',
    width: '145px',
    color:'#0b81d6'
  },
  volume: {
    fontSize: '11px',
    width: '70px',
    color:'#c56342'
  }
}))

const mapDispatchToProps = {
  updateCartQty,
  deleteCartItem
}

export const CartItem = connect(
  null,
  mapDispatchToProps
)(props => {
  const { item, showVol, updateCartQty, deleteCartItem,dptId } = props
  const classes = useStyles()

  const updateQty = event => {
    updateCartQty(item.product, Number.parseInt(event.target.value))
  }
  return (
    <ListItem className={classes.root}>
      <Typography variant="h6" color="textPrimary">
        {item.product.name}
      </Typography>

      <div className={classes.container}>
        <div className={classes.inner}>
          <Typography component="div" variant="body1" color="textPrimary">
            ${item.price.toFixed(2)}
            &nbsp;&nbsp;&nbsp;x&nbsp;&nbsp;&nbsp;
          </Typography>
          <TextField
            type="number"
            InputLabelProps={{
              shrink: true
            }}
            margin="dense"
            value={item.quantity}
            onChange={updateQty}
            inputProps={{ min: '1', step: '1' }}
            className={classes.qty}
          />

          <Typography
            component="div"
            variant="body1"
            color="textPrimary"
            className={classes.amount}
          >
            &nbsp;&nbsp;&nbsp;=&nbsp;&nbsp;&nbsp;{dptId===14?'USD$ ':'$'}
            {(item.price * item.quantity).toFixed(2)}
          </Typography>

          {showVol&& <Typography
            component="div"
            variant="body1"
            color="textPrimary"
            className={classes.volume}
          >
            Vol:&nbsp;{(item.product.volume_rate * item.quantity * 100).toFixed(2)}%
          </Typography>}
        </div>
        <Button
          size="small"
          color="secondary"
          onClick={() => deleteCartItem(item.product)}
        >
          Remove
        </Button>
      </div>
    </ListItem>
  )
})
