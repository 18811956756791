import React, { useState, useEffect } from 'react'
import {
  Grid,
  Card,
  CardContent,
  TablePagination,
  CardActions
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoSnackBar } from './InfoSnackBar'
import { Filter } from './Filter'
import { OrderList } from './OrderList'
import { getOrders } from '@/webservices/DataSource'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  filter: {
    marginTop: theme.spacing(1)
    // height: '800px'
  },
  filterCard: {
    // height: '100%'
  }
}))

const Msg = () => {
  return (
    <React.Fragment>
      This system only provides document information after October 1st 2019.
      <br />
      The following content is not a substitute for the statement. If you have
      any needs, please contact customer service staff.
    </React.Fragment>
  )
}

export function Orders(props) {
  const classes = useStyles()

  const orderTypeList = [
    { name: 'ALL', id: 1 },
    { name: 'INVOICE', id: 2 },
    { name: 'Credit Note', id: 3 }
  ]
  const [orderTypeSelected, setorderTypeSelected] = useState([])
  const [orders, setOrders] = useState([])
  const handleOrderType = (event, newType) => {
    const type = newType.filter(
      t => !(orderTypeSelected.findIndex(os => os === t) > -1)
    )
    setorderTypeSelected(type)
  }

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [orderNumber, setOrderNumber] = useState('')

  const handleNumber = event => {
    setOrderNumber(event.target.value)
  }

  const fetchOrders = async (newPage = 1, newPerPage = 20) => {
    const params = {
      page: newPage,
      per_page: newPerPage
    }
    if (startDate) {
      params.start_date = startDate
    }
    if (endDate) {
      params.end_date = endDate
    }
    if (orderNumber !== '') {
      params.order_number = orderNumber
    }

    if (orderTypeSelected.length > 0) {
      params.type = orderTypeSelected[0]
    }

    const data = await getOrders(params)
    setOrders(data.data)
    const { current_page, total, per_page } = data.meta
    setCnt(Number.parseInt(total))
    setPage(Number.parseInt(current_page) - 1)
    setPerPage(Number.parseInt(per_page))
  }

  const initFetchOrders = async (newPage = 1, newPerPage = 20) => {
    const params = {
      page: newPage,
      per_page: newPerPage
    }
    const data = await getOrders(params)
    setOrders(data.data)
    const { current_page, total, per_page } = data.meta
    setCnt(Number.parseInt(total))
    setPage(Number.parseInt(current_page) - 1)
    setPerPage(Number.parseInt(per_page))
  }

  useEffect(() => {
    initFetchOrders()
  }, [])

  const reset = () => {
    setStartDate(null)
    setEndDate(null)
    setOrderNumber('')
    setorderTypeSelected([])
  }
  const [cnt, setCnt] = useState(0)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(20)

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <InfoSnackBar>
          <Msg />
        </InfoSnackBar>
      </Grid>
      <Grid item xs={12} className={classes.filter}>
        <Card className={classes.filterCard}>
          <CardContent>
            <Filter
              orderType={{
                name: 'Type',
                selects: orderTypeList,
                selected: orderTypeSelected,
                selectCallback: handleOrderType
              }}
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              orderNumber={orderNumber}
              handleOrderNumber={handleNumber}
              searchCallback={fetchOrders}
              resetCallback={reset}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} className={classes.filter}>
        <Card>
          <CardContent>
            <OrderList data={orders} />
          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
              component="div"
              count={cnt}
              onChangePage={(event, newPage) =>
                fetchOrders(newPage + 1, perPage)
              }
              onChangeRowsPerPage={event =>
                fetchOrders(1, Number.parseInt(event.target.value))
              }
              page={page}
              rowsPerPage={perPage}
              rowsPerPageOptions={[20, 30, 40]}
            />
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )
}
