import React, { useState, useEffect } from 'react'
import {
  Grid,
  Card,
  CardContent,
  TextField,
  Typography,
  Button
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoSnackBar } from './InfoSnackBar'
import { OrderItemsEdit } from './OrderItemsEdit'
import { withRouter } from 'react-router-dom'
import { getOrder, updateOrder } from '@/webservices/DataSource'
import { MessageAlert } from '@/components/MessageAlert'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  header: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  title: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    display: 'flex',
    alignItems: 'center'
  },
  btn: {
    marginLeft: theme.spacing(1)
  },
  info: {
    display: 'flex',
    marginTop: theme.spacing(1)
  },
  infoLabel: {
    flex: '0 0 100px',
    textAlign: 'right'
    // height: '800px'
  },
  infoInfo: {
    flex: '1 0 0',
    paddingLeft: theme.spacing(2)
  },
  textDense: {
    '& input': {
      paddingTop: '6.5px',
      paddingBottom: '6.5px'
    }
  },
  textBlue: {
    color: theme.palette.textBlue
  },
  textRed: {
    color: theme.palette.textRed
  }
}))

function EditPage(props) {
  const classes = useStyles()
  const { match, history } = props
  const id = match.params.id
  const [order, setOrder] = useState({
    phone: '',
    address: '',
    note: '',
    details: []
  })

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  const [alertType] = useState('success')

  useEffect(() => {
    getOrder(id).then(data => {
      const tmpOrder = data.data
      setOrder({
        ...tmpOrder,
        details: tmpOrder.details.map(item => ({ ...item, checked: false }))
      })
    })
  }, [id])

  const handleAddressChange = event => {
    setOrder({ ...order, address: event.target.value })
  }

  const handlePhoneChange = event => {
    setOrder({ ...order, phone: event.target.value })
  }

  const handleNoteChange = event => {
    setOrder({ ...order, note: event.target.value })
  }

  const handleCheckChange = id => {
    setOrder({
      ...order,
      details: order.details.map(item => {
        if (item.id === id) {
          item.checked = !item.checked
        }
        return item
      })
    })
  }

  const handleDeleteLines = () => {
    setOrder({
      ...order,
      details: order.details.filter(item => !item.checked)
    })
  }

  const handleChangeQty = (id, event) => {
    setOrder({
      ...order,
      details: order.details.map(item => {
        if (item.id === id) {
          item.quantity = Number.parseInt(event.target.value)
        }
        return item
      })
    })
  }

  const sumitOrder = () => {
    const { details, ...params } = order
    params.lines = details.map((item, index) => ({
      id: item.id,
      seq: index + 1,
      productId: item.product_id,
      quantity: item.quantity
    }))

    updateOrder(id, params)
      .then(data => {
        setAlertMsg('Modified success')
        setAlertOpen(true)
        history.push(`/orders/${id}`)
      })
      .catch(err => {})
  }

  const handleCancel = () => {
    history.push(`/orders/${id}`)
  }
  return (
    <React.Fragment>
      <MessageAlert
        open={alertOpen}
        closeCallback={setAlertOpen}
        message={alertMsg}
        variant={alertType}
      />
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <InfoSnackBar />
        </Grid>
        <Grid item xs={12} className={classes.header}>
          <div className={classes.title}>
            <Typography variant="h4">Invoice Edit</Typography>
          </div>
          <div className={classes.actions}>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={sumitOrder}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              className={classes.btn}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={3} className={classes.info}>
                  <div className={classes.infoLabel}>Number</div>
                  <div className={clsx(classes.infoInfo, classes.textBlue)}>
                    {order.no}
                  </div>
                </Grid>
                <Grid item xs={3} className={classes.info}>
                  <div className={classes.infoLabel}>Type</div>
                  <div className={clsx(classes.infoInfo, classes.textBlue)}>
                    {order.type}
                  </div>
                </Grid>
                <Grid item xs={6} className={classes.info}>
                  <div className={classes.infoLabel}>Date</div>
                  <div className={clsx(classes.infoInfo, classes.textRed)}>
                    {order.date}
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={clsx(classes.info, classes.actions)}
                >
                  <div className={classes.infoLabel}>Address</div>
                  <div className={classes.infoInfo}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={order.address}
                      onChange={handleAddressChange}
                      classes={{ root: classes.textDense }}
                    ></TextField>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={6}
                  className={clsx(classes.info, classes.actions)}
                >
                  <div className={classes.infoLabel}>Note</div>
                  <div className={classes.infoInfo}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={order.note}
                      onChange={handleNoteChange}
                      classes={{ root: classes.textDense }}
                    ></TextField>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={3}
                  className={clsx(classes.info, classes.actions)}
                >
                  <div className={classes.infoLabel}>Phone</div>
                  <div className={classes.infoInfo}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      value={order.phone}
                      onChange={handlePhoneChange}
                      classes={{ root: classes.textDense }}
                    ></TextField>
                  </div>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} className={classes.filter}>
          <Card>
            <CardContent>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleDeleteLines}
                >
                  Delete
                </Button>
              </div>
              <OrderItemsEdit
                items={order.details}
                checkCallback={handleCheckChange}
                changeQtyCallback={handleChangeQty}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export const OrderEdit = withRouter(EditPage)
