import React from 'react'
import { Chip } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  green: {
    color: '#67c23a',
    backgroundColor: '#f0f9eb',
    borderColor: 'e1f3d8'
  },
  blue: {
    color: '#409eff',
    backgroundColor: '#ecf5ff',
    borderColor: '#d9ecff'
  },
  red: {
    color: '#f56c6c',
    backgroundColor: '#fef0f0',
    borderColor: '#fde2e2'
  },
  tag: {
    marginLeft: theme.spacing(1)
  }
}))

export const Status = props => {
  const classes = useStyles()
  let color
  switch (props.status) {
    case 'Waiting Approve':
      color = 'green'
      break
    case 'Loading & Shipping':
    case 'Awaiting Payment':
    case 'Approved':
      color = 'blue'
      break
    case 'Cancelled by Staff':
    case 'Cancelled by Customer':
      color = 'red'
      break
    default:
      color = ''
  }

  return (
    <Chip
      className={clsx(color !== '' && classes[color], classes.tag)}
      label={props.status}
    ></Chip>
  )
}
