import { ADD_CART, DELETE_CART, CLEAR_CART, UPDATE_CART } from './ActionTypes'

export const CartReducer = (store, action) => {
  const { product, quantity, price } = action.payload || {}
  let newStore
  switch (action.type) {
    case ADD_CART:
      const existing = store.find(item => item.product.id === product.id)
      if (existing) {
        newStore = store.map(item => {
          if (item.product.id === product.id) {
            return {
              ...item,
              quantity: item.quantity + quantity
            }
          } else {
            return item
          }
        })
      } else {
        newStore = store.concat([
          {
            product: product,
            quantity: quantity,
            price: price
          }
        ])
      }
      localStorage.setItem('cart', JSON.stringify(newStore))
      return newStore
    case UPDATE_CART:
      newStore = store.map(item => {
        if (item.product.id === product.id) {
          return {
            ...item,
            quantity: quantity
          }
        } else {
          return item
        }
      })
      localStorage.setItem('cart', JSON.stringify(newStore))
      return newStore

    case DELETE_CART:
      newStore = store.filter(item => item.product.id !== product.id)
      localStorage.setItem('cart', JSON.stringify(newStore))
      return newStore

    case CLEAR_CART:
      localStorage.removeItem('cart')
      return []
    default:
      const data = localStorage.getItem('cart')
      if (data) {
        return JSON.parse(data)
      } else {
        return []
      }
  }
}
