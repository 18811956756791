import React from 'react'
import { TableCell, TableRow, withStyles, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow)

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: 'center'
  },
  bold: {
    fontWeight: 'bold'
  },
  viewBtn: {
    padding: '4px',
    height: '30px'
  }
}))

export const CheckedDetailsRow = props => {
  const classes = useStyles()
  const item = props.item

  let actionBtn = null
  if (item.id > 0) {
    actionBtn = (
      <Button
        size="small"
        variant="outlined"
        className={classes.viewBtn}
        href={`#/orders/${item.id}`}
        target="_blank"
      >
        View
      </Button>
    )
  }
  return (
    <StyledTableRow>
      <TableCell className={classes.center}>{actionBtn}</TableCell>
      <TableCell className={classes.center}>{item.Number}</TableCell>
      <TableCell className={classes.center}>{item.Date}</TableCell>
      <TableCell></TableCell>
      <TableCell className={classes.center}>{item.DueDate}</TableCell>
      <TableCell className={clsx(classes.center, classes.bold)}>
        {item.Total}
      </TableCell>
      <TableCell className={clsx(classes.center, classes.bold)}>
        {item.Paid}
      </TableCell>
      <TableCell className={clsx(classes.center, classes.bold)}>
        {item.Credited}
      </TableCell>
      <TableCell className={clsx(classes.center, classes.bold)}>
        {item.Due}
      </TableCell>
    </StyledTableRow>
  )
}
