import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import { LogoutContext } from '@/App'
import { connect } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  logo: {
    color: 'white'
  },
  info: {
    display: 'flex',
    alignItems: 'center'
  },
  infoName: {
    color: 'white',
    marginLeft: theme.spacing(1)
  },
  divider: {
    border: '1px solid white',
    height: '30px',
    marginLeft: theme.spacing(2)
  },
  actionButton: {
    marginLeft: theme.spacing(2),
    color: 'white',
    fontWeight: 'bold'
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutTitle: {
    fontSize: '20px'
  }
}))

const mapStateToPros = state => ({
  account: state.common
})

const Topbar = connect(
  mapStateToPros,
  null
)(props => {
  const { className, onSidebarOpen, account, dispatch, ...rest } = props

  const classes = useStyles()
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false)

  const getLogoImage = () => {
    if (!account.logo) return <AccountCircleIcon style={{ fontSize: 30 }} />
    if (account.department_id===4) {
      return <img src='http://shop.tyresys.com.au/storage/logo/onyx_tyres.png' alt="logo" />
    }else if (account.department_id===7) {
      return <img src='http://shop.tyresys.com.au/storage/logo/cypress_tyres.png' alt="logo" />
    }else if (account.department_id===8) {
      return <img src='http://shop.tyresys.com.au/storage/logo/cypress_gc.png' alt="logo" />
    }else if (account.department_id===9) {
      return <img src='http://shop.tyresys.com.au/storage/logo/onyx_sydney.png' alt="logo" />
    }else if (account.department_id===10) {
      return <img src='http://shop.tyresys.com.au/storage/logo/onyx_sydney.png' alt="logo" />
    }else if (JSON.parse(account.logo).cusLogo) {
      return <img src={JSON.parse(account.logo).cusLogo} alt="logo" />
    }else {
      return <img src={account.logo} alt="logo" />
    }
  }

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={logoutDialogOpen}
      >
        <DialogTitle
          id="confirmation-dialog-title"
          disableTypography
          classes={{ root: classes.logoutTitle }}
        >
          Confirm to Log Out ？
        </DialogTitle>
        <DialogContent>Thanks for using this system.</DialogContent>
        <DialogActions>
          <LogoutContext.Consumer>
            {logout => (
              <React.Fragment>
                <Button
                  autoFocus
                  variant="contained"
                  onClick={() => setLogoutDialogOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setLogoutDialogOpen(false)
                    logout()
                  }}
                  variant="contained"
                  color="primary"
                >
                  SURE
                </Button>
              </React.Fragment>
            )}
          </LogoutContext.Consumer>
        </DialogActions>
      </Dialog>
      <Toolbar>
        {getLogoImage()}
        <RouterLink className={classes.actionButton} to="/search">
          Search
        </RouterLink>
        <RouterLink className={classes.actionButton} to="/orders">
          Orders
        </RouterLink>
        <RouterLink className={classes.actionButton} to="/account">
          Account
        </RouterLink>

        <div className={classes.flexGrow} />
        <div className={classes.info}>
          <Avatar>H</Avatar>
          <Typography className={classes.infoName} variant="h5">
            {account.name || ''}
          </Typography>
        </div>
        <div className={classes.divider}></div>
        <IconButton
          className={classes.signOutButton}
          color="inherit"
          onClick={() => setLogoutDialogOpen(true)}
        >
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  )
})

export default Topbar
