import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'

import { Topbar, Footer } from './components'

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    },
    display: 'flex',
    justifyContent: 'center'
  },
  content: {
    height: '100%',
    maxWidth: '1400px',
    width: '100%'
  }
}))

const Main = props => {
  const { children } = props

  const classes = useStyles()
  return (
    <div
      className={clsx({
        [classes.root]: true
      })}
    >
      <Topbar />
      <div className={classes.content}>
        {children}
        <Footer />
      </div>
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node
}

export default Main
