import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

export const RedBtn = withStyles(theme => ({
  root: {
    color: '#f56c6c',
    backgroundColor: '#fef0f0',
    borderColor: '#fbc4c4',
    '&:hover': {
      color: '#fff',
      backgroundColor: '#f56c6c',
      borderColor: '#f56c6c'
    }
  }
}))(Button)
