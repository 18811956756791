import Axios from 'axios'
import store from '@/store/DataStore'

const BASE_URL = '/api/cus/'

export const getProducts = async params => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}products`,
    params: params
  })

  return response.data
}

export const getCrossStock = async arrayObj => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}cross-stock`,
    params: arrayObj
  })

  return response.data
}

export const getAccount = async () => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}account`
  })

  return response.data
}

export const getCategories = async () => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}categories`
  })

  return response.data
}

export const getCenterBores = async () => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}center_bore`
  })

  return response.data
}

export const getBoltPatterns = async () => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}bolt_pattern`
  })

  return response.data
}

export const getBrands = async () => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}brands`
  })

  return response.data
}
// 获取获取价格限制
export const getSearchLimit = async (cid) => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}shop-price-limit/${cid}`
  })

  return response.data
}
// 减少剩余获取价格次数
export const searchLimitReduce = async (cid) => {
  const response = await Axios.request({
    method: 'PUT',
    url: `${BASE_URL}shop-reduce-remain/${cid}`
  })

  return response.data
}
export const getPrices = async id => {
  const { common } = store.getState()
  const limitRes = await getSearchLimit(common.id)
  const limit = limitRes.data || {}
  // 开启的情况下 ，剩余次数小于1
  if (Number(limit.get_price_switch) === 1 && !limit.remaining_searches > 0) {
    const str = 'The credit for getting prices has been used up, please call us to adjust the credit.'
    throw new Error(str)
  }
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}price/${id}`
  })
  // 开启的情况下 ，剩余次数减1
  if (Number(limit.get_price_switch) === 1) {
    searchLimitReduce(common.id)
  }
  return response.data
}

export const postOrder = async params => {
  const response = await Axios.request({
    method: 'POST',
    url: `${BASE_URL}orders`,
    data: params
  })

  return response.data
}

export const updateOrder = async (id, params) => {
  const response = await Axios.request({
    method: 'PUT',
    url: `${BASE_URL}orders/${id}`,
    data: params
  })

  return response.data
}

export const getOrders = async params => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}orders`,
    params: params
  })

  return response.data
}

export const getOrder = async id => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}orders/${id}`
  })

  return response.data
}

export const voidOrder = async id => {
  const response = await Axios.request({
    method: 'POST',
    url: `${BASE_URL}orders/${id}/void`
  })

  return response.data
}

export const getBalance = async () => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}balance`
  })

  return response.data
}

export const getAgedReceivables = async () => {
  const response = await Axios.request({
    method: 'GET',
    url: `${BASE_URL}aged-receivables`
  })

  return response.data
}
