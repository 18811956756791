import Axios from 'axios'

export const login = async (email, password) => {
  const param = {
    grant_type: 'password',
    client_id: 2,
    client_secret: 'rddOcAMU0Fn454Hv4ScJ27EVLprgqB29VhsVPGSi',
    username: email,
    password: password,
    scope: ''
  }
  const response = await Axios.post('/oauth/token', param)
  return response.data
}
