import React from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  withStyles
} from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import { OrderItemsTaxInfo } from './OrderItemsTaxInfo'

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow)

const useStyles = makeStyles(theme => ({
  actionCell: {
    display: 'inline-flex'
  },
  smCell: {
    padding: '6px'
  },
  header: {
    backgroundColor: '#D7D7D7',
    color: '#000000',
    fontWeight: 700,
    fontSize: '12px'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  }
}))
export const OrderItems = props => {
  const classes = useStyles()
  return (
    <PerfectScrollbar>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell className={classes.center}>#</TableCell>
            <TableCell>Item Name</TableCell>
            <TableCell className={classes.center}>Unit</TableCell>
            <TableCell className={classes.center}>Quantity</TableCell>
            <TableCell className={classes.center}>Unit Price</TableCell>
            <TableCell className={classes.center}>Line Amount</TableCell>
            <TableCell className={classes.center}>Tax Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((item, index) => (
            <StyledTableRow key={item.id}>
              <TableCell className={classes.center}>{index + 1}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell className={classes.center}>{item.unit}</TableCell>
              <TableCell className={classes.center}>{item.quantity}</TableCell>
              <TableCell className={classes.center}>{item.price}</TableCell>
              <TableCell className={classes.center}>{item.amount}</TableCell>
              <TableCell className={classes.center}>GST on Income</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <OrderItemsTaxInfo order={props.order} />
    </PerfectScrollbar>
  )
}
