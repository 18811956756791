import React, { useState, useEffect } from 'react'
import { HashRouter as Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/styles'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/css/index.css'
import SignIn from './views/SignIn'
import Routes from './Routes'
import store from './store/DataStore'
import { Provider } from 'react-redux'
import { getAccount } from '@/webservices/DataSource'
import { updateAccount } from '@/store/CommonActionCreator'
import Axios from 'axios'
import { MessageAlert } from './components/MessageAlert'

export const LogoutContext = React.createContext(() => {})

if (localStorage.getItem('access_token')) {
  Axios.interceptors.request.use(
    config => {
      config.headers.Authorization = localStorage.getItem('access_token')
      return config
    },
    error => {
      return Promise.reject(error)
    }
  )
}

const App = () => {
  const [token, setToken] = useState(localStorage.getItem('access_token'))

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  const [alertType, setAlertType] = useState('success')
  const [alertAutoClose, setAlertAutoClose] = useState(true)

  Axios.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response) {
        if (error.response.status === 401) {
          setAlertMsg('Login info expired, please login again')
          setAlertType('error')
          setAlertOpen(true)
          return Promise.reject(error)
        }

        if (error.response.status === 404) {
          setAlertMsg('404 Not Found')
          setAlertType('error')
          setAlertOpen(true)
          return Promise.reject(error)
        }

        if (error.response.status === 499) {
          setAlertMsg(
            'Current Account can not login, please contact the administrator'
          )
          setAlertType('error')
          setAlertOpen(true)
          setToken(null)
          localStorage.removeItem('access_token')
          return Promise.reject(error)
        }

        if (error.response.status === 429) {
          setAlertMsg('Too many attempts, please slow down the fresh pace')
          setAlertType('warning')
          setAlertOpen(true)
          setToken(null)
          localStorage.removeItem('access_token')
          return Promise.reject(error)
        }
        if (error.response.status === 500) {
          const data = error.response.data
          let messages
          try {
            messages = JSON.parse(data)
          } catch (err) {
            messages = data
          }

          // other errors
          if (messages instanceof Object) {
            messages = messages.message || ''
            if (messages instanceof Array) {
              messages = messages
                .map(item => {
                  return `<p>${item}</p>`
                })
                .join('')
            }
          }
          setAlertMsg(messages)
          setAlertType('error')
          setAlertOpen(true)
          setAlertAutoClose(false)
        } else {
          setAlertMsg("Unknown Error")
          setAlertType('error')
          setAlertOpen(true)
          setAlertAutoClose(false)
        }
      } else {
        setAlertMsg('Http Error')
        setAlertType('error')
        setAlertOpen(true)
      }
      return Promise.reject(error)
    }
  )

  useEffect(() => {
    if (token) {
      Axios.interceptors.request.use(
        config => {
          config.headers.Authorization = token
          return config
        },
        error => {
          return Promise.reject(error)
        }
      )
      getAccount()
        .then(data => store.dispatch(updateAccount(data)))
        .catch(err => {})
    }
  }, [token])

  const loginHandler = token => {
    Axios.interceptors.request.use(
      config => {
        config.headers.Authorization = token
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )
    setToken(token)
    setTimeout(() => {
      window.location.reload()
    }, 200)
  }
  const logout = () => {
    localStorage.removeItem('access_token')
    setToken(null)
    setTimeout(() => {
      window.location.reload()
    }, 200)
  }

  if (token) {
    return (
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <LogoutContext.Provider value={logout}>
            <MessageAlert
              open={alertOpen}
              closeCallback={setAlertOpen}
              message={alertMsg}
              variant={alertType}
              autoClose={alertAutoClose}
            />
            <Router>
              <Routes logoutCallback={logout} />
            </Router>
          </LogoutContext.Provider>
        </Provider>
      </ThemeProvider>
    )
  } else {
    return (
      <ThemeProvider theme={theme}>
        <MessageAlert
          open={alertOpen}
          closeCallback={setAlertOpen}
          message={alertMsg}
          variant={alertType}
          autoClose={alertAutoClose}
        />

        <Router>
          <SignIn loginCallback={loginHandler} />
        </Router>
      </ThemeProvider>
    )
  }
}

export default App
