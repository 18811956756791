import React from 'react'
import { Select, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '8px'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  label: {
    flex: '0 0 100px',
    display: 'flex',
    alignItems: 'center'
  }
}));

const useSelectStyles = makeStyles(theme => ({
  root:{
    paddingTop: '11px',
    paddingBottom: '11px',
    width: '100px'
  }
}))

export const DropdownMenu = props => {
  const classes = useStyles()
  const selectClasses = useSelectStyles()
  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <div className={classes.label}>{props.name}</div>
      <Select
        native
        value={props.selected}
        onChange={props.selectCallback}
        classes={selectClasses}
      >
        <option aria-label="None" value={0} />
        {props.selects.map(item => {
          return <option key={item.id} value={item.name}>{item.name}</option>
        })}
      </Select>
    </FormControl>
  )
}
