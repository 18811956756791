import React, { useState, useEffect, useCallback } from 'react'
import { Grid, Card, CardContent, TablePagination, CardActions, AppBar, Tabs, Tab } from '@material-ui/core'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { TyreFilter } from './TyreFilter'
import { PirreliFilter } from './PirreliFilter'
import { WheelFilter } from './WheelFilter'
import { Result } from './Result'
import { Cart } from './Cart'
import { getProducts, getCrossStock, getBrands, getCategories, getCenterBores, getBoltPatterns } from '@/webservices/DataSource'
import FiberNewIcon from '@material-ui/icons/FiberNew';
import { InfoSnackBar } from '../Orders/InfoSnackBar'


const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  resultContainer: {
    marginTop: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tab: {
    textTransform: 'none'
  },
  icon: {
    color: '#fff',
    marginTop: '-12px'
  },
  tabImg: {
    width: '90px',
    height: '18px'
  },
  tabTitleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    border: '5px solid',
    borderColor: '#dbdcef',
    borderRightColor: '#3f51b5',
    animation: '$spinner-s 1s infinite linear',
  },
  '@keyframes spinner-s': {
    'to': {
      transform: 'rotate(1turn)'
    }
  }
}))

const TypeOptions = [
  { id: 1, type: 'TBR', name: 'DRIVE' },
  { id: 2, type: 'TBR', name: 'TRAILER' },
  { id: 3, type: 'TBR', name: 'STEER' },
  { id: 4, type: 'TBR', name: 'TRAILER/STEER' },
  { id: 5, type: 'TBR', name: 'ALL POSITION' },
  { id: 6, type: 'TBR', name: 'TIPPER DRIVE' },
  { id: 7, type: 'TBR', name: 'BLOCK DRIVE' },
  { id: 8, type: 'TBR', name: 'WHEEL' },
  { id: 9, type: 'PCR', name: 'HP' },
  { id: 10, type: 'PCR', name: 'UHP' },
  { id: 11, type: 'PCR', name: 'RUNFLAT' },
  { id: 12, type: 'PCR', name: 'MT' },
  { id: 13, type: 'PCR', name: 'AT' },
  { id: 14, type: 'PCR', name: 'HT' },
  { id: 15, type: 'PCR', name: 'COMM/LT' },
  { id: 16, type: 'PCR', name: 'SUV' },
  { id: 17, type: 'PCR', name: 'RT' }
]
// CONTINENTAL 在以下部门的枚举
const CONTINENTALIdOfDepMap = {
  4: 154,
  7: 161,
  8: 165,
  10: 213,
}
const mapStateToProps = state => ({ account: state.common })

export function SearchMain(props) {
  const classes = useStyles()
  const dptId = props.account.department_id
  const CONTINENTALIdByDpt = CONTINENTALIdOfDepMap[dptId]
  const [categoryList, setCategoryList] = useState([])
  const [categorySelected, setCategorySelected] = useState([])

  const [dp14CateToggle, setDp14CateToggle] = useState('')
  const [dp14CateL1Options] = useState([{ id: 'TBR', name: 'TBR' }, { id: 'PCR', name: 'PCR' }])
  const [dp14CateL1Selected, setDp14CateL1Selected] = useState([])

  const [pirelliCategoryList, setPirelliCategoryList] = useState([])
  const [pirelliCategorySelected, setPirelliCategorySelected] = useState([])

  const [brandList, setBrandList] = useState([])
  const [brandSelected, setBrandSelected] = useState([])
  // const [pirelliList, setPirelliList] = useState([])
  const [continentalList, setContinentalList] = useState([])

  // const [pirelliSelected, setPirelliSelected] = useState([])

  const [rimDiameterList] = useState(rimDiameters)
  const [rimDiameterSelected, setRimDiameterSelected] = useState(null)
  const [rimWidthList] = useState(rimWidths)
  const [rimWidthSelected, setRimWidthSelected] = useState(null)
  const [offsetList] = useState(offsets)
  const [offsetSelected, setOffsetSelected] = useState(null)
  const [offsetFilled, setOffsetFilled] = useState('')
  const [centerBoreList, setCenterBoreList] = useState([])
  const [centerBoreSelected, setCenterBoreSelected] = useState(0)
  const [boltPatternList, setBoltPatterns] = useState([])
  const [boltPatternSelected, setBoltPatternSelected] = useState(0)
  const [materialList] = useState(materials)
  const [materialSelected, setMaterialSelected] = useState([])

  const [expressList] = useState(expresses)
  const [expressSelected, setExpressSelected] = useState([])
  const [spec, setSpec] = useState('')

  const [cnt, setCnt] = useState(0)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(20)

  const handleCategories = (event, newCategoires) => {
    setCategorySelected(newCategoires)
  }
  const handleDp14CateL1 = (event, val) => {
    setCategorySelected([])
    setDp14CateL1Selected(val)
    setDp14CateToggle(val)
  }
  const handlePirelliCategories = (event, newCategoires) => {
    setPirelliCategorySelected(newCategoires)
  }
  const handleBrands = (event, newBrands) => {
    setBrandSelected(newBrands)
  }
  const handlePirelliBrands = () => {
    // setPirelliSelected([134])
    return
  }
  const handleExpress = (event, newExpress) => {
    setExpressSelected(newExpress)
  }

  const handRimDiameter = (event, value) => {
    setRimDiameterSelected(value)
  }
  const handleRimWidth = (event, value) => {
    setRimWidthSelected(value)
  }
  const handleOffset = (isSelected, value) => {
    if (isSelected) {
      setOffsetSelected(value)
    } else {
      const regex = /^-?\d*\.?\d*$/
      if (!value || regex.test(value)) {
        setOffsetFilled(value)
      }
    }
  }
  const handleCenterBore = (event) => {
    setCenterBoreSelected([event.target.value])
  }
  const handleBoltPattern = (event) => {
    setBoltPatternSelected([event.target.value])
  }
  const handleMaterial = (event, value) => {
    setMaterialSelected(value)
  }
  const handleSpec = event => {
    setSpec(event.target.value)
  }

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)


  const fetchCategories = async () => {
    const res = await getCategories()
    const data = res.map((d, index) => ({ id: index + 1, name: d }))
    const pirelliData = data.filter(item => item.name !== 'TBR' && item.name !== 'COMM')
    setCategoryList(data)
    setPirelliCategoryList(pirelliData)
  }

  const fetchCenterBores = async () => {
    const data = await getCenterBores()
    setCenterBoreList(data.map((d, index) => ({ id: index + 1, name: d })))
  }

  const fetchBoltPatterns = async () => {
    const data = await getBoltPatterns()
    setBoltPatterns(data.map((d, index) => ({ id: index + 1, name: d })))
  }

  const fetchBrands = useCallback(async () => {
    const data = await getBrands()
    setBrandList(data)
    // setPirelliList([{ id: 134, name: 'PIRELLI' }])
    setContinentalList([{ id: CONTINENTALIdByDpt, name: 'CONTINENTAL' }])
  }, [CONTINENTALIdByDpt])

  const wheelsTabIndex = () => CONTINENTALIdByDpt ? 2 : 1

  const crossStockReq = async (data) => {
    let prodArry = data.map(i => i.id)
    const param = {
      product_ids: prodArry.join(),
      target_department_id: 7 //写死了的布里斯班
    }
    const crossData = await getCrossStock(param)
    let newData = data.map(i => {
      let found = crossData.find(d => d.name === i.name)
      if (found) {
        let text = found.total_stock === 0 ? 'out of stock' : found.total_stock >= 8 ? '8+' : found.total_stock
        i.warehouse_availability.push({
          name: 'Brisbane',
          id: 9999,
          availability: text
        })
      }
      return i
    })
    return newData
  }

  const initFetchProduct = useCallback(async (newPage = 1, newPerPage = 20) => {
    setLoading(true)
    const params = {
      page: newPage,
      per_page: newPerPage
    }
    const { data, meta } = await getProducts(params)

    if ((dptId === 12 || dptId === 8) && data.length !== 0) { //Zapp账套专属逻辑，2.加一个dptId===8也按他的逻辑
      await crossStockReq(data)
    }
    setProducts(data)
    const { current_page, total, per_page } = meta
    setCnt(Number.parseInt(total))
    setPage(Number.parseInt(current_page) - 1)
    setPerPage(Number.parseInt(per_page))
    setLoading(false)
  }, [dptId])

  useEffect(() => {
    fetchBrands()
  }, [fetchBrands])
  useEffect(() => {
    fetchCategories()
    fetchCenterBores()
    fetchBoltPatterns()
  }, [])

  useEffect(() => {
    if (dptId) {
      initFetchProduct()
    }
  }, [dptId, initFetchProduct])

  const fetchProducts = async (newPage = 1, newPerPage = 20) => {
    setLoading(true)
    const params = {
      page: newPage,
      per_page: newPerPage,
      product_category: 0
    }
    if (tabIndex === 0) {
      params.product_category = 1 // tyre
      if (categorySelected.length > 0) {
        params.categories = categorySelected.map(c => {
          let item = ''
          if (dptId === 14) { //特殊处理 部门14
            item = TypeOptions.find(ca => ca.id === c)
          } else {
            item = categoryList.find(ca => ca.id === c)
          }
          return item.name || ''
        })
      }
      if (brandSelected.length > 0) {
        params.brands = brandSelected
      }
      if (expressSelected.length > 0) {
        params.express = expressSelected
      }

    } else if (tabIndex === 1 && CONTINENTALIdByDpt) {
      params.product_category = 1 // pirelli
      if (pirelliCategorySelected.length > 0) {
        params.categories = pirelliCategorySelected.map(c => {
          const item = pirelliCategoryList.find(ca => ca.id === c)
          return item.name || ''
        })
      }
      params.brands = [CONTINENTALIdByDpt]
    } else {
      params.product_category = 2 // wheel
      params.rim_diameter = rimDiameterSelected
      params.rim_width = rimWidthSelected
      const regexFinal = /(^-?\d+$)|(^-?\d\.{1}\d+$)/
      params.offset = offsetSelected ? [...offsetSelected] : []
      if (regexFinal.test(offsetFilled)) {
        params.offset.push(offsetFilled)
      }
      params.center_bore = centerBoreSelected
      params.bolt_pattern = boltPatternSelected
      if (materialSelected.length > 0) {
        params.materials = materialSelected
      }
      if (expressSelected.length > 0) {
        params.express = expressSelected
      }
    }
    if (spec !== '') {
      params.spec = spec
    }

    const { data, meta } = await getProducts(params)
    if ((dptId === 12 || dptId === 8) && data.length !== 0) { //Zapp账套专属逻辑，2.加一个dptId===8也按他的逻辑
      await crossStockReq(data)
    }
    setProducts(data)

    const { current_page, total, per_page } = meta
    setCnt(Number.parseInt(total))
    setPage(Number.parseInt(current_page) - 1)
    setPerPage(Number.parseInt(per_page))
    setLoading(false)
  }

  const reset = () => {
    setCategorySelected([])
    setPirelliCategorySelected([])
    setBrandSelected([])
    setExpressSelected([])
    setRimDiameterSelected(null)
    setRimWidthSelected(null)
    setOffsetSelected(null)
    setOffsetFilled('')
    setCenterBoreSelected(0)
    setBoltPatternSelected(0)
    setExpressSelected([])
    setMaterialSelected([])
    setSpec('')
  }

  const [tabIndex, setTabIndex] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue)
  }

  const holidayUntilDate = () => {
    if (props.account.logo && !props.account.logo.startsWith("http")) {
      let showFlag = JSON.parse(props.account.logo || '{}')?.show
      // return showFlag && props.account.department_id===4?true:false
      return showFlag
    } else {
      return false
    }
  }

  return (
    <Grid container spacing={2} className={classes.container}>
      {holidayUntilDate() &&
        <Grid item lg={12} xs={12}>
          <InfoSnackBar holidayNotice={true}>
            {JSON.parse(props.account.logo)?.text}
          </InfoSnackBar>
        </Grid>}
      <Grid item lg={8} xs={12}>
        <Grid>
          <AppBar position="static">
            <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="filters">
              <Tab key='Tyres' label="Tyres" className={classes.tab} />
              {
                // 有id才显示
                CONTINENTALIdByDpt &&
                <Tab key='CONTINENTAL' label={<div className={classes.tabTitleBox} >
                  <span>CONTINENTAL</span>
                  <FiberNewIcon className={classes.icon} /></div>
                } className={classes.tab} />
              }
              <Tab key='Wheels' label="Wheels" className={classes.tab} />
            </Tabs>
          </AppBar>
          <Card hidden={tabIndex !== 0}>
            <CardContent>
              <TyreFilter
                dptId={props.account.department_id}
                categories={{
                  name: 'Category',
                  selects: categoryList,
                  selected: categorySelected,
                  selectCallback: handleCategories
                }}
                dp14CateToggle={dp14CateToggle}
                dp14CateL1={{
                  name: 'Category',
                  exclusive: true,
                  selects: dp14CateL1Options,
                  selected: dp14CateL1Selected,
                  selectCallback: handleDp14CateL1
                }}
                dp14CateL2TBR={{
                  name: 'TBR Type',
                  selects: TypeOptions.filter(i => i.type === 'TBR'),
                  selected: categorySelected,
                  selectCallback: handleCategories
                }}
                dp14CateL2PCR={{
                  name: 'PCR Type',
                  selects: TypeOptions.filter(i => i.type === 'PCR'),
                  selected: categorySelected,
                  selectCallback: handleCategories
                }}
                brands={{
                  name: 'Brand',
                  selects: brandList,
                  selected: brandSelected,
                  selectCallback: handleBrands
                }}
                express={{
                  name: 'Express',
                  selects: expressList,
                  selected: expressSelected,
                  selectCallback: handleExpress
                }}
                spec={spec}
                specCallback={handleSpec}
                searchCallback={fetchProducts}
                resetCallback={reset}
              />
            </CardContent>
          </Card>
          {CONTINENTALIdByDpt && <Card hidden={tabIndex !== 1}>
            <CardContent>
              <PirreliFilter
                categories={{
                  name: 'Category',
                  selects: pirelliCategoryList,
                  selected: pirelliCategorySelected,
                  selectCallback: handlePirelliCategories
                }}
                // brands={{
                //   name: 'Brand',
                //   selects: pirelliList,
                //   selected: [134],
                //   selectCallback: handlePirelliBrands
                // }}

                brands={{
                  name: 'Brand',
                  selects: continentalList,
                  selected: continentalList[0] ? [continentalList[0].id] : [],
                  selectCallback: handlePirelliBrands
                }}
                // express={{
                //   name: 'Express',
                //   selects: expressList,
                //   selected: expressSelected,
                //   selectCallback: handleExpress
                // }}
                spec={spec}
                specCallback={handleSpec}
                searchCallback={fetchProducts}
                resetCallback={reset}
              />
            </CardContent>
          </Card>
          }
          <Card hidden={tabIndex !== wheelsTabIndex()}>
            <CardContent>
              <WheelFilter
                rimDiameters={{
                  name: 'Rim Diameter',
                  selects: rimDiameterList,
                  selected: rimDiameterSelected,
                  selectCallback: handRimDiameter
                }}
                rimWidths={{
                  name: 'Rim Width',
                  selects: rimWidthList,
                  selected: rimWidthSelected,
                  selectCallback: handleRimWidth
                }}
                offsets={{
                  name: 'Offset',
                  selects: offsetList,
                  selected: offsetSelected,
                  filled: offsetFilled,
                  selectCallback: handleOffset
                }}
                centerBores={{
                  name: 'Center Bore',
                  selects: centerBoreList,
                  selected: centerBoreSelected,
                  selectCallback: handleCenterBore
                }}
                boltPatterns={{
                  name: 'Bolt Patterns',
                  selects: boltPatternList,
                  selected: boltPatternSelected,
                  selectCallback: handleBoltPattern
                }}
                express={{
                  name: 'Express',
                  selects: expressList,
                  selected: expressSelected,
                  selectCallback: handleExpress
                }}
                materials={{
                  name: 'Materials',
                  selects: materialList,
                  selected: materialSelected,
                  selectCallback: handleMaterial
                }}
                spec={spec}
                specCallback={handleSpec}
                searchCallback={fetchProducts}
                resetCallback={reset}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid className={classes.resultContainer}>
          <Card>
            <CardContent>
              {loading ? <div className={classes.spinner}></div> :
                <Result data={products} />
              }
            </CardContent>
            <CardActions className={classes.actions}>
              <TablePagination
                component="div"
                count={cnt}
                onChangePage={(event, newPage) =>
                  fetchProducts(newPage + 1, perPage)
                }
                onChangeRowsPerPage={event =>
                  fetchProducts(1, Number.parseInt(event.target.value))
                }
                page={page}
                rowsPerPage={perPage}
                rowsPerPageOptions={[20, 30, 40]}
              />
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Grid item lg={4} className={classes.cart}>
        <Card>
          <CardContent>
            <Cart />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export const Main = connect(mapStateToProps, null)(SearchMain)

const rimDiameters = [
  { name: '12', id: '12' },
  { name: '13', id: '13' },
  { name: '14', id: '14' },
  { name: '15', id: '15' },
  { name: '16', id: '16' },
  { name: '17', id: '17' },
  { name: '17.5', id: '17.5' },
  { name: '18', id: '18' },
  { name: '19', id: '19' },
  { name: '19.5', id: '19.5' },
  { name: '20', id: '20' },
  { name: '21', id: '21' },
  { name: '22.5', id: '22.5' },
  { name: '23', id: '23' },
  { name: '24', id: '24' },
  { name: '26', id: '26' }
]

const rimWidths = [
  { name: '4.5', id: '4.5' },
  { name: '5', id: '5' },
  { name: '5.5', id: '5.5' },
  { name: '6', id: '6' },
  { name: '6.5', id: '6.5' },
  { name: '7', id: '7' },
  { name: '7.5', id: '7.5' },
  { name: '8', id: '8' },
  { name: '8.5', id: '8.5' },
  { name: '9', id: '9' },
  { name: '9.5', id: '9.5' },
  { name: '10', id: '10' },
  { name: '10.5', id: '10.5' },
  { name: '11', id: '11' },
  { name: '11.5', id: '11.5' },
  { name: '12', id: '12' }
]

const offsets = [
  { name: '0', id: '0' },
  { name: '10', id: '10' },
  { name: '20', id: '20' },
  { name: '30', id: '30' },
  { name: '-10', id: '-10' },
  { name: '-20', id: '-20' },
  { name: '-30', id: '-30' }
]

const materials = [
  { name: 'Alloy', id: 2 },
  { name: 'Steel', id: 3 }
]

const expresses = [
  { name: 'Special', id: 1 },
  { name: 'Clearance', id: 2 }
]