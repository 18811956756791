import React from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  Checkbox,
  withStyles
} from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import { OrderItemsTaxInfo } from './OrderItemsTaxInfo'

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))(TableRow)

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1)
  },
  actionCell: {
    display: 'inline-flex'
  },
  smCell: {
    padding: '6px'
  },
  header: {
    backgroundColor: '#D7D7D7',
    color: '#000000',
    fontWeight: 700,
    fontSize: '12px'
  },
  center: {
    textAlign: 'center'
  },
  right: {
    textAlign: 'right'
  }
}))
export const OrderItemsEdit = props => {
  const classes = useStyles()

  return (
    <PerfectScrollbar className={classes.root}>
      <Table size="small">
        <TableHead>
          <TableRow className={classes.header}>
            <TableCell></TableCell>
            <TableCell>#</TableCell>
            <TableCell>Item Name</TableCell>
            <TableCell className={classes.center}>Unit</TableCell>
            <TableCell className={classes.center}>Quantity</TableCell>
            <TableCell className={classes.center}>Unit Price</TableCell>
            <TableCell className={classes.center}>Line Amount</TableCell>
            <TableCell className={classes.center}>Tax Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.items.map((item, index) => (
            <StyledTableRow key={item.id}>
              <TableCell>
                <Checkbox
                  checked={item.checked}
                  onChange={() => props.checkCallback(item.id)}
                />
              </TableCell>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell className={classes.center}>{item.unit}</TableCell>
              <TableCell className={classes.center}>
                <TextField
                  type="number"
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="dense"
                  value={item.quantity}
                  inputProps={{ min: '1', step: '1' }}
                  onChange={event => props.changeQtyCallback(item.id, event)}
                ></TextField>
              </TableCell>
              <TableCell className={classes.center}>{item.price}</TableCell>
              <TableCell className={classes.center}>
                {item.price * item.quantity}
              </TableCell>
              <TableCell className={classes.center}>GST on Income</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <OrderItemsTaxInfo items={props.items} />
    </PerfectScrollbar>
  )
}
