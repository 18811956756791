import React, { useState, useEffect } from 'react'
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import { connect } from 'react-redux'
import { addToCart } from '@/store/CartActionCreator'
import { ResultRow } from './ResultRow'
import AlertFast from '@/components/MessageAlert/AlertFast'

const useStyles = makeStyles(theme => ({
  smCell: {
    padding: '6px'
  },
  header: {
    backgroundColor: '#D7D7D7',
    color: '#000000',
    fontWeight: 700,
    fontSize: '12px'
  },
  center: {
    textAlign: 'center'
  }
}))

const mapStateToProps = state => ({
  carts: state.cart,
  common: state.common,
})

const mapDispatchToProps = {
  addToCart
}

export const Result = connect(
  mapStateToProps,
  mapDispatchToProps
)(props => {
  const classes = useStyles()
  const dptId = props.common.department_id
  const [rows, setRows] = useState(props.data)
  const [alertMsg, setAlertMsg] = useState(null)

  useEffect(() => {
    setRows(props.data)
  }, [props.data])

  return (
    <>
      <PerfectScrollbar>
        <Table size="small">
          <TableHead>
            <TableRow className={classes.header}>
              {/* //缩略图列的标题 */}
              {dptId === 14 && <TableCell classes={{ sizeSmall: classes.smCell }}></TableCell>}
              <TableCell classes={{ sizeSmall: classes.smCell }}>
                Product Name
              </TableCell>
              <TableCell classes={{ sizeSmall: classes.smCell }}>
                Brand
              </TableCell>
              <TableCell classes={{ sizeSmall: classes.smCell }}
                className={classes.center}>
                Availability
              </TableCell>
              <TableCell classes={{ sizeSmall: classes.smCell }}>
                {dptId === 14 ? 'FOB AUD' : 'Special Price'}
                <br />
                {dptId === 14 ? 'exclusive' : '(Incl. GST)'}
              </TableCell>
              <TableCell classes={{ sizeSmall: classes.smCell }}>
                {dptId === 14 ? 'FOB USD' : 'Best Price'}
                <br />
                {dptId === 14 ? 'inclusive' : '(Incl. GST)'}
              </TableCell>
              <TableCell
                classes={{ sizeSmall: classes.smCell }}
                className={classes.center}>
                Add To
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(product => (
              <ResultRow
                setAlertMsg={setAlertMsg}
                key={product.id}
                account={props.common}
                data={product}
                addToCart={props.addToCart}
              />
            ))}
          </TableBody>
        </Table>
      </PerfectScrollbar>
      <AlertFast msg={alertMsg} type="warning" onClose={() => setAlertMsg('')} />
    </>
  )
})
