import React from 'react'

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Button,
  Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  tableHeader: {
    backgroundColor: '#D7D7D7',
    color: '#000000',
    fontWeight: 700,
    fontSize: '12px'
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.background.default
    }
  }
}))
export const ProductConfirm = props => {
  const classes = useStyles()

  const totalQty = props.items.reduce((sum, item) => sum + item.quantity, 0)
  const totalAmount = props.items.reduce(
    (sum, item) => sum + item.quantity * item.price,
    0
  )
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={props.open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>
        <div className={classes.header}>
          <Typography variant="h4">Step 1: Confirm Products</Typography>
          <Typography variant="h4">
            Total Qty: {totalQty} | Total Amount: {totalAmount.toFixed(2)}
          </Typography>
        </div>
      </DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow className={classes.tableHeader}>
              <TableCell>Item Name</TableCell>
              <TableCell>QTY.</TableCell>
              <TableCell>Unit Price</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.items.map(item => (
              <TableRow key={item.product.id} classes={{ root: classes.row }}>
                <TableCell>{item.product.name}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>{item.price.toFixed(2)}</TableCell>
                <TableCell>{(item.quantity * item.price).toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.cancelCallback}>Back</Button>
        <Button color="primary" onClick={props.nextCallback}>
          Next
        </Button>
      </DialogActions>
    </Dialog>
  )
}
