import { createStore, combineReducers } from 'redux'
import { CartReducer } from './CartReducer'
import { CommonReducer } from './CommonReducer'

export default createStore(
  combineReducers({
    cart: CartReducer,
    common: CommonReducer
  })
)
