import React, { useState } from 'react'
import {
  List,
  ListItem,
  Button,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core'
import { CartItem } from './CartItem'
import { ProductConfirm } from './ProductConfirm'
import { InfoConfirm } from './InfoConfirm'
import { connect } from 'react-redux'
import { deleteCartItem, clearCart } from '@/store/CartActionCreator'
// import { getBalance } from '@/webservices/DataSource'
import { MessageAlert } from '@/components/MessageAlert'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px',
    backgroundColor: '#D7D7D7',
    '& h5': {
      // color: '#000000',
      // fontWeight: 'bold',
      fontSize: '13px'
    },
  },
  volumeText1: {
    color: '#0b81d6',
    fontWeight: 'bold',
  },
  volumeText2: {
    color: '#c56342',
    fontWeight: 'bold',
  },
}))

const mapStateToProps = state => ({
  cartItems: state.cart,
  common: state.common,
})

const mapDispatchToProps = {
  deleteCartItem,
  clearCart
}

const connectFunction = connect(mapStateToProps, mapDispatchToProps)

let queryFlag = false

export const Cart = connectFunction(props => {
  const carts = props.cartItems
  const dptId = props.common.department_id
  
  const setting = JSON.parse(props.common?.logo || '{"volShow":false}')

  const classes = useStyles()

  const [itemDialogOpen, toggleItemDialog] = useState(false)
  const [infoDialogOpen, toggleInfoDialog] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  // const [alertMsg, setAlertMsg] = useState('')
  const [alertMsg] = useState('')
  const [alertType] = useState('warning')

  const next = () => {
    toggleItemDialog(false)
    toggleInfoDialog(true)
  }

  const cancel = () => {
    toggleItemDialog(false)
    toggleInfoDialog(false)
  }

  const submitted = () => {
    props.clearCart()
    toggleItemDialog(false)
    toggleInfoDialog(false)
  }

  const handleCheckOut = async () => {
    if (queryFlag) {
      return
    } else {
      queryFlag=true
      // fix: 要求去掉提示，但是保留代码
      // const data = await getBalance()
      // let creditLimit = Number.parseInt(data.data.creditLimit) || 0
      // let xeroBalance = Number.parseFloat(data.data.xeroBalance) || 0
      // if (creditLimit !==0 && xeroBalance > creditLimit) {
      //   setAlertMsg(`The amount you owe $${xeroBalance} is greater than credit limit $${creditLimit}. Please make a payment or call us.`)
      //   setAlertOpen(true)
      //   setTimeout(() => {queryFlag=false}, 500);
      //   return
      // }else {
        toggleItemDialog(!itemDialogOpen)
        setTimeout(() => {queryFlag=false}, 500);
      // }
    }
  
  }

  const checkOutBtnAbled = carts.length > 0
  return (
    <React.Fragment>
      <MessageAlert
        open={alertOpen}
        closeCallback={setAlertOpen}
        message={alertMsg}
        variant={alertType}
      />
      <List>
        <ListItem className={classes.header}>
          <Button variant="text" onClick={props.clearCart}>
            Clear All
          </Button>
          <Button
            disabled={!checkOutBtnAbled}
            variant="text"
            color="primary"
            onClick={handleCheckOut}
          >
            Check out
          </Button>
        </ListItem>
        <Divider />
        <ListItem className={classes.info}>
          <Typography variant="h5">
            Total Qty:&nbsp;&nbsp; {carts.reduce((sum, item) => sum + item.quantity, 0)}
          </Typography>
          <Typography variant="h5" className={classes.volumeText1}>
            {dptId===14?'Total USD$:':'Total Amt: $'}&nbsp;
            {carts
              .reduce((sum, item) => sum + item.quantity * item.price, 0)
              .toFixed(2)}
          </Typography>
          
          {setting.volShow && 
            <Typography variant="h5" className={classes.volumeText2}>
              40' Vol:&nbsp;&nbsp;
              {carts
                .reduce((sum, item) => sum + item.quantity * item.product.volume_rate *100, 0)
                .toFixed(2)} %
            </Typography>
          }
        </ListItem>
      </List>
      {carts.map(item => (
        <CartItem showVol={setting.volShow} item={item} key={item.product.id} dptId={dptId}/>
      ))}

      <ProductConfirm
        open={itemDialogOpen}
        items={carts}
        nextCallback={next}
        cancelCallback={cancel}
      />
      <InfoConfirm
        open={infoDialogOpen}
        submitCallback={submitted}
        cancelCallback={cancel}
        cart={carts}
      />
    </React.Fragment>
  )
})
