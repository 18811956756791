import React, { useState } from 'react'
import 'react-dates/initialize'
import { DateRangePicker } from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import { TextField, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { ToggleSelection } from '@/components/ToggleSelection'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  row: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    flex: '0 0 100px'
  },
  content: {
    flex: '1 0 0'
  },
  textDense: {
    '& input': {
      paddingTop: '6.5px',
      paddingBottom: '6.5px'
    }
  },
  buttonContainer: {
    marginTop: theme.spacing(1)
  },
  cancel: {
    marginLeft: theme.spacing(2)
  }
}))

export const Filter = props => {
  const classes = useStyles()

  const [focusedInput, setfocusedInput] = useState(null)
  return (
    <form>
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.label}>
            <Typography>Date</Typography>
          </div>
          <div className={classes.content}>
            <DateRangePicker
              displayFormat="D MMM Y"
              small
              showClearDates
              appendToBody
              startDate={props.startDate} // momentPropTypes.momentObj or null,
              startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
              endDate={props.endDate} // momentPropTypes.momentObj or null,
              endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
              onDatesChange={({ startDate, endDate }) => {
                props.setStartDate(startDate)
                props.setEndDate(endDate)
              }} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              onFocusChange={focusedInput => setfocusedInput(focusedInput)} // PropTypes.func.isRequired,
              isDayBlocked={function noRefCheck() {}}
              isDayHighlighted={function noRefCheck() {}}
              isOutsideRange={function noRefCheck() {}}
            />
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.label}>
            <Typography>Number</Typography>
          </div>
          <div className={classes.content}>
            <TextField
              margin="dense"
              placeholder="Number keyword"
              variant="outlined"
              fullWidth
              classes={{ root: classes.textDense }}
              value={props.orderNumber}
              onChange={props.handleOrderNumber}
            />
          </div>
        </div>
      </div>

      <ToggleSelection {...props.orderType} />

      <div className={classes.buttonContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={() => props.searchCallback()}
        >
          Search
        </Button>
        <Button
          variant="contained"
          className={classes.cancel}
          onClick={props.resetCallback}
        >
          Reset
        </Button>
      </div>
    </form>
  )
}
