import { ADD_CART, UPDATE_CART, DELETE_CART, CLEAR_CART } from './ActionTypes'

export const addToCart = (product, quantity, price) => ({
  type: ADD_CART,
  payload: {
    product,
    quantity,
    price
  }
})

export const updateCartQty = (product, quantity) => ({
  type: UPDATE_CART,
  payload: {
    product,
    quantity
  }
})

export const deleteCartItem = product => ({
  type: DELETE_CART,
  payload: {
    product
  }
})

export const clearCart = () => ({
  type: CLEAR_CART,
  payload: {}
})
