import React, { useState, useEffect } from 'react'
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogActions
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { InfoSnackBar } from './InfoSnackBar'
import { OrderItems } from './OrderItems'
import { getOrder, voidOrder } from '@/webservices/DataSource'
import { withRouter } from 'react-router-dom'
import { Status } from './Status'
import { RedBtn } from '@/components/ColorBtn'
import { MessageAlert } from '@/components/MessageAlert'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(2)
  },
  header: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },

  title: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  actions: {
    display: 'flex',
    alignItems: 'center'
  },
  btn: {
    marginLeft: theme.spacing(1)
  },
  btnVoid: {
    color: '#f56c6c',
    backgroundColor: '#fef0f0',
    borderColor: '#fde2e2'
  },
  info: {
    display: 'flex',
    marginTop: theme.spacing(2)
  },
  infoLabel: {
    flex: '0 0 100px',
    textAlign: 'right'
    // height: '800px'
  },
  infoInfo: {
    flex: '1 0 0',
    paddingLeft: theme.spacing(2)
  },
  textBlue: {
    color: theme.palette.textBlue
  },
  textRed: {
    color: theme.palette.textRed
  }
}))
const Msg = () => {
  return (
    <React.Fragment>
      This system only provides document information after 1st November 2019.
      <br />
      The following content is not a substitute for the statement. If you have
      any needs, please contact customer service staff.
    </React.Fragment>
  )
}
export const OrderDetail = withRouter(props => {
  const { match, history } = props
  const classes = useStyles()
  const id = match.params.id

  const [order, setOrder] = useState({ details: [] })
  useEffect(() => {
    getOrder(id).then(data => setOrder(data.data))
  }, [id])

  const handleEdit = () => {
    history.push(`/orders/${id}/edit`)
  }

  const [alertOpen, setAlertOpen] = useState(false)
  const [alertMsg, setAlertMsg] = useState('')
  const [alertType] = useState('success')
  const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false)
  const isEditable = order.type === 'Invoice' && order.status === 'Waiting Approve'

  const handleVoid = () => {
    voidOrder(id)
      .then(data => {
        setAlertMsg('Cancelled success')
        setAlertOpen(true)
        getOrder(id).then(data => setOrder(data.data))
      })
      .catch(err => {})
  }

  const handleGoback = () => {
    history.goBack()
  }
  return (
    <Grid container spacing={2} className={classes.container}>
      <Dialog
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth="xs"
        fullWidth
        aria-labelledby="confirmation-dialog-title"
        open={cancelConfirmOpen}
      >
        <DialogTitle
          id="confirmation-dialog-title"
          disableTypography
          classes={{ root: classes.logoutTitle }}
        >
          Confirm to Cancel This Order？
        </DialogTitle>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            onClick={() => setCancelConfirmOpen(false)}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setCancelConfirmOpen(false)
              handleVoid()
            }}
            variant="contained"
            color="primary"
          >
            SURE
          </Button>
        </DialogActions>
      </Dialog>
      <MessageAlert
        open={alertOpen}
        closeCallback={setAlertOpen}
        message={alertMsg}
        variant={alertType}
      />
      <Grid item xs={12}>
        <InfoSnackBar>
          <Msg />
        </InfoSnackBar>
      </Grid>
      <Grid item xs={12} className={classes.header}>
        <div className={classes.title}>
          {order.type === 'Credit Note'?<Typography variant="h4">Credit Note Detail</Typography>:
          <Typography variant="h4">Invoice Detail</Typography>}
          <Status status={order.status}></Status>
        </div>
        <div className={classes.actions}>
          {isEditable && (
            <RedBtn
              variant="contained"
              className={classes.btn}
              onClick={() => setCancelConfirmOpen(true)}
            >
              Cancel
            </RedBtn>
          )}
          {isEditable && (
            <Button
              variant="contained"
              className={classes.btn}
              onClick={handleEdit}
              color="primary"
            >
              Edit
            </Button>
          )}

          <Button
            variant="contained"
            className={classes.btn}
            onClick={handleGoback}
          >
            Back
          </Button>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={3} className={classes.info}>
                <div className={classes.infoLabel}>Number</div>
                <div className={clsx(classes.infoInfo, classes.textBlue)}>
                  {order.no}
                </div>
              </Grid>
              <Grid item xs={3} className={classes.info}>
                <div className={classes.infoLabel}>Type</div>
                <div className={clsx(classes.infoInfo, classes.textBlue)}>
                  {order.type}
                </div>
              </Grid>
              <Grid item xs={6} className={classes.info}>
                <div className={classes.infoLabel}>Date</div>
                <div className={clsx(classes.infoInfo, classes.textRed)}>
                  {order.date}
                </div>
              </Grid>
              <Grid item xs={6} className={classes.info}>
                <div className={classes.infoLabel}>Address</div>
                <div className={classes.infoInfo}>{order.address}</div>
              </Grid>
              <Grid item xs={6} className={classes.info}>
                <div className={classes.infoLabel}>Note</div>
                <div className={classes.infoInfo}>{order.note}</div>
              </Grid>
              <Grid item xs={3} className={classes.info}>
                <div className={classes.infoLabel}>Phone</div>
                <div className={classes.infoInfo}>{order.phone}</div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <OrderItems order={order} items={order.details} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
})
