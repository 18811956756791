import React from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { Main as MainLayout } from './layouts'
import { RouteWithLayout } from './components'

import { SearchMain } from './views/Search'
import { Orders } from './views/Orders'
import { OrderDetail } from './views/Orders/OrderDetail'
import { OrderEdit } from './views/Orders/OrderEdit'
import { Account } from './views/Account'

const Routes = props => {
  return (
    <Switch>
      <RouteWithLayout
        component={SearchMain}
        exact
        path="/search"
        layout={MainLayout}
      />
      <RouteWithLayout
        component={Orders}
        exact
        path="/orders"
        layout={MainLayout}
      />
      <RouteWithLayout
        component={OrderDetail}
        exact
        path="/orders/:id"
        layout={MainLayout}
      />
      <RouteWithLayout
        component={OrderEdit}
        exact
        path="/orders/:id/edit"
        layout={MainLayout}
      />
      <RouteWithLayout
        component={Account}
        exact
        path="/account"
        layout={MainLayout}
      />
      <Redirect to="/search" />
    </Switch>
  )
}

export default Routes
