import React from 'react'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
// import { makeStyles } from '@material-ui/core/styles'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  label: {
    flex: '0 0 100px'
  },
  content: {
    flex: '1 0 0'
  },
  group: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
    marginLeft: `-${theme.spacing(1)}px`
  },
  groupCell: {
    borderLeft: '1px solid rgba(0, 0, 0, 0.12) !important ',
    borderRadius: '4px !important',
    marginLeft: `${theme.spacing(1)}px !important`
  },
  groupSm: {
    height: '30px'
  },
  cell: {
    marginBottom: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#3f51b5',
      color: 'white'
    },
    '&$selected': {
      backgroundColor: '#3f51b5',
      color: 'white'
    },
    '&$selected:hover': {
      backgroundColor: '#3f51b5',
      color: 'white'
    }
  },
  selected: {}
}))

export const ToggleSelection = props => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.label}>{props.name}</div>
      <div className={classes.content}>
        <ToggleButtonGroup
          value={props.selected}
          size="small"
          classes={{
            root: classes.group,
            grouped: classes.groupCell
          }}
          onChange={props.selectCallback}
          exclusive={Boolean(props.exclusive)}
        >
          {props.selects.map(s => (
            <ToggleButton
              size="small"
              key={s.id}
              value={s.id}
              classes={{
                root: classes.cell,
                selected: classes.selected
              }}
            >
              {s.name}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </div>
    </div>
  )
}
