import React from 'react'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles(theme => ({
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  footerContent: {
    width: '600px',
    display: 'flex',
    '& .num': {
      color: theme.palette.textRed
    },
    '&>div:not(:first-child)': {
      marginLeft: '50px'
    }
  },
  tax: {
    marginTop: theme.spacing(2),
    fontSize: '12px'
  }
}))

export const OrderItemsTaxInfo = ({order}) => {
  const classes = useStyles()
  // const qty = props.items.reduce((sum, item) => sum + item.quantity, 0)
  // const amount = props.items.reduce(
  //   (sum, item) => sum + item.quantity * item.price,
  //   0
  // )
  return (
    <div className={classes.footer}>
      <div className={classes.footerContent}>
        <div>
          Total Quantity <span className="num"> {order.qty}</span>
        </div>
        <div>
          <div>
            Total Amount <span className="num">{order.amount}</span>
          </div>
          <div className={classes.tax}>
            [ Total Tax (GST 10%){' '}
            {/* <span className="num">{(amount * 0.1).toFixed(2)} </span>/ SubTotal{' '} */}
            <span className="num">{order.taxAmount} </span>/ SubTotal{' '}
            {/* <span className="num">{(amount * 0.9).toFixed(2)}</span> ] */}
            <span className="num">{order.subTotal}</span> ]
          </div>
        </div>
      </div>
    </div>
  )
}
